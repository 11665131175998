
#especialidades
  margin-top 100px
  .copy
    h3
      @extend .h3-sub

    h2 
      @extend .h2-sub
    
    p 
      @extend .p-sub

      margin-top 2rem
      margin-bottom 4rem
      max-width 350px
      +middle()
        max-width: 100%
  .especialidades-cards  

    .especialidades-item
      max-width 290px
      margin-bottom 2rem

      .img-especilidade
        max-width 55px
        height 55px
        +mobile()
          margin 0 auto
      
      h3 
        @extend .h3-sub
        font-weight 600
        margin 1rem 0
      p 
        @extend .p-12
        font-weight 600
        color $subtitle-color