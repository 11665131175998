#page-contato
  margin-top: 100px
  .form-contatos
    img
      width: 20px
      height 20px
      object-fit contain

    h3
      @extend .h3-sub

    h2 
      @extend .h2-sub
    
    p 
      @extend .p-sub
      margin 0.3rem 0

    .tel, .mail
      p, a
        @extend .p-sub
        color #022543!important
        font-weight: 700!important

    .btn-whatsapp 
      margin-top 2rem !important
      margin-bottom 2rem!important
      mobile()
        margin 2rem auto!important

    .horario, .local
      margin-top 10px
      h4
        @extend .p-sub
        color #022543!important
        font-weight: 700!important
        
      p
        font-weight: 400!important
        color $subtitle-color

  form 
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    margin-top: 1rem;
    max-width: 37.18rem;
    +mobile()
      display: flex
      flex-direction: column
        
    label 
      margin-bottom: 1rem
      display: block

      &.msg 
        grid-column:span 2
 
      input 
        width 100%
        height 2.5rem
        padding 14px
        border 1px solid rgba(0,92,137, 0.18)
        border-radius 4px
    
        &::placeholder
          @extend .p-12
          font-weight 600
          color #a9a9a9

      textarea 
        padding 14px
        border 0
        border-radius 4px
        width 100%
        height 6rem
        border 1px solid rgba(0,92,137, 0.18)
        &::placeholder
          font-family 'Open Sans', sans-serif 
          font-size 12px
          font-weight 600
          color #a9a9a9
      
    button 
      grid-column 2 / 3
      grid-row 5 / 6
      justify-self flex-end

      max-width 160px
      width 100%
      height 50px
      margin 1rem 0

      background: linear-gradient(90deg, rgba(43,113,181,1) 0%, rgba(24,79,123,1) 100%);
      border none
      border-radius: 4px

      font-family 'Open Sans', sans-serif 
      font-size 16px
      font-weight: 700
      color #fff

      +mobile()
        grid-column 1 / 3
        justify-self flex-start
        max-width 100%
        &::after
          width 100%
      



