#footer
  background $primaryBlue
  padding 2rem 0 0 0
  margin-top: 100px

  +mobile()
    margin-top 0
  .sobre
    .logo-footer
      max-width: 202px
    p
      @extend .p-12
      font-weight 400
      color #fff
      margin 1.5rem 0 2rem 0
      a
        color #49b1df !important

    .endereco
      img
        max-width 17px
        height 23px

  .contatos
    text-align: right 
    padding-top 1rem
    
    .btn-whats
      @extend .btn-whatsapp
      margin-left auto !important

    p 
      @extend .p-12
      font-weight 600
      color #fff
      margin 0.5rem 0
      img
        width 16px
        height 16px
        object-fit: contain

  .redes
    margin-left auto !important

  .rodape
    background: #004363
    color #fff
    @extend .p-12
    padding 1rem 0
    margin-top 2rem

    .xceller
      justify-content end

      .img-3xceller
        width 77px 
        height 23px

  .mega-menu-rodape
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    // grid-auto-flow: column;
    justify-content: center;
    gap: 1rem

     
  // Footer Mobile

  #contato-footer
    .form-contato-mobile
      text-align: center
      h3
        @extend .h3-sub
        color #00bbfe

      h2 
        @extend .h2-sub
        color #fff
      
      p 
        @extend .p-sub
        color #fff
        margin 1.5rem 0

      .contato
        display: flex
        align-items: center
        gap 1rem
        justify-content: center
   
        img
          max-width: 30px
          height 30px
        p
          font-size: 22px
          font-weight: 700

    .xceller
      justify-content center
      color #fff
      padding 1.5rem 0

      .img-3xceller
        width 77px 
        height 23px

    form 
      display: flex;
      flex-direction: column
      max-width: 37.18rem;
      margin: 0 auto
      margin-top: 2.5rem;
      label 
        margin-bottom: 1.5rem
        display: block

        &.msg 
          grid-column:span 2
          textarea
            background #e6effe
    
        input 
          width 100%
          height 2.5rem
          padding 14px
          border 1px solid rgba(0,92,137, 0.18)
          border-radius 4px
          background #e6effe
          &::placeholder
            @extend .p-12
            font-weight 600
            color #a9a9a9

        select
          width 100%
          height 2.5rem
          padding 0 14px
          border 0
          border-radius 4px
          background #e6effe
          border 1px solid rgba(0,92,137, 0.18)
          @extend .p-12
          font-weight 600
          color #a9a9a9   

          &::placeholder
            @extend .p-12
            font-weight 600
            color #a9a9a9      
          option
            @extend .p-12
            font-weight 600
            color #a9a9a9   
            

        textarea 
          padding 14px
          border 0
          border-radius 4px
          width 100%
          height 6rem
          border 1px solid rgba(0,92,137, 0.18)
          &::placeholder
            font-family 'Open Sans', sans-serif 
            font-size 12px
            font-weight 600
            color #a9a9a9
        
      button
        +mobile()
          width 100%
        &::after
          +mobile()
            width 100%

