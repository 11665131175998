#quem-somos
  margin-top 4rem

  .img-quem-somos
    margin-left -12%
    max-width 960px
    height 460px
    background: url('../imgs/imagem-quem-somos.jpg')
    background-size cover
    background-repeat no-repeat
    background-position 93% 0
    margin-right 4rem
    +middle()
      margin-right 0
      margin-top 2rem
    
    +mobile()
      max-width 290px
      height 235px
      margin 2rem auto


  .copy-quem-somos
    +middle()
      text-align: center

    h3
      @extend .h3-sub
    

    h2 
      @extend .h2-sub
    
    p 
      @extend .p-sub
      margin-top 2rem
      max-width 470px

      margin-bottom 2rem

      +middle()
        max-width 100%

