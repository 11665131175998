mobile()
  @media (max-width: 600px)
    {block}

middle()
  @media (max-width: 1000px)
    {block}


desktop()
  @media (min-width: 1000px)
    {block}

.grid 
  display grid
  gap 1rem

.flex
  display flex


.btn-whatsapp
  width 180px
  min-width: 180px
  height 50px
  background linear-gradient(0deg, rgba(20,204,158,1) 0%, rgba(19,185,143,1) 100%)
  border-radius 4px
  border: none
  outline: none
  z-index 2

  position relative
  display flex
  align-items center
  justify-content: center
  gap 8px

  font-family 'Open Sans', sans-serif
  color #fff!important
  font-weight: 700
  font-size 14px
  text-align: left
  transition 0.2s
  &::after
    content: ""
    width 180px
    min-width 180px
    position absolute
    top 0
    right 0
    left 0
    bottom 0
    box-shadow 0px 9px 24px 0px rgba(35,204,161,0.5)  
    z-index 1
    border-radius 4px

  &:hover
    filter brightness(1.1)

.img-btn
  max-width: 22px
  max-height: 22px

.container
  max-width: 1140px
  +mobile()
    max-width: 320px

.btn-saiba
  width 180px
  min-width: 180px
  height 50px
  border-radius 4px
  background: linear-gradient(0deg, rgba(86,204,242,1) 0%, rgba(90,164,234,1) 100%) no-repeat
  border: none
  outline: none
  position relative
  z-index 2

  display flex
  align-items center
  justify-content: center
  gap: 8px

  font-family 'Open Sans', sans-serif
  color #fff!important
  font-weight: 700
  font-size 14px
  text-align: left
  transition 0.2s

  &:hover
    filter brightness(0.92)

  &::after
    content: ""
    width 160
    min-width: 160px
    position: absolute
    top: 0
    right: 0
    left: 0
    bottom: 0
    box-shadow 0px 9px 24px 0px rgba(5,117,230,0.5)
    border-radius 4px
    z-index 1

.img-btn-saiba
  max-width: 13px
  max-height: 11px
  margin-left 0.5rem

@media (min-width: 1000px)
  .hide-on-desktop
    display none !important

@media (max-width: 1000px)
  .hide-on-mobile
    display none !important

.h3-sub
  font-family 'Open Sans', sans-serif 
  font-size 14px
  color $textBlue
  font-weight 700
  letter-spacing -0.4px 
  margin-bottom 16px
  +middle()
    font-size: 12px

.h2-sub
  font-family 'Open Sans', sans-serif 
  font-size 36px
  color $darkBlue
  font-weight 700
  letter-spacing -0.4px 
  line-height 1.3
  margin-bottom: 1rem
  +middle()
    font-size: 24px

.p-sub
  font-family 'Open Sans', sans-serif 
  font-size 14px
  color $subtitle-color
  font-weight 600
  letter-spacing -0.4px 
  line-height: 1.4

.p-12
  font-family 'Open Sans', sans-serif 
  font-size 12px

.splide__pagination
  bottom: -1.5em;
  text-align: center
  li
    button
      background:#48b8e1  
.splide__pagination__page.is-active
  background:#48b8e1  
  opacity 0.8

#banner-internas 
  height 180px
  width 100%
  background: url('../imgs/bg-paginas-internas.jpg') no-repeat
  background-size: cover
  
  .title
    height 180px
    h1
      font-family 'Open Sans', sans-serif
      font-size 40px
      color #fff
      font-weight 700
      letter-spacing -0.4px
      max-width 5000px
      line-height: 1
      text-align: center;

      +middle()
        font-size 27px
        max-width 100%

.loading
  position: fixed
  width: 100vw
  height: 100vh
  background #fff
  top 0
  left 0
  right 0
  bottom 0
  z-index 99999999
  opacity 0.5
  display: none;
  justify-content: center;
  align-items: center;
  &.loading-ativo
    display: flex;
  img
    max-width: 32px
    height: 32px
    top: 50%; 
    left: 50%;
    animation: spin-animation 1s infinite;
    animation-timing-function: linear;

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}