#club-desconto

  margin-top 100px
  +middle()
    margin-top 50px

  .copy
    text-align center
    max-width 670px
    margin 0 auto
    h3
      @extend .h3-sub

    h2 
      @extend .h2-sub
      margin 1.5rem 0
      +middle()
        margin 1rem 0
    
    p 
      @extend .p-sub
      margin 0 auto
  
  .card-planos
    margin-top: 2rem
    margin-bottom: -2rem
    position: relative
    z-index: 2

    .splide__slide
      display: flex
      justify-content: center
      

    .item-plano
      width 172px
      height 172px
      background $whiteBlue
      padding: 2rem 1rem

      border-radius: 4px
      transition: background 0.2s

      cursor: pointer
      margin 0 auto

      +mobile()
        margin 0 auto

      img
        max-width 172px
        max-height: 50px
        object-fit: contain
        margin: 0 auto
        transition: filter 0.2s

      h3 
        font-size: 15px
        font-weight: 600
        color $primaryBlue
        text-align: center
        margin-top: 1rem
        transition: color 0.2s

    
      &:hover, &.active
        background $primaryBlue
        img 
          filter: grayscale(85%) drop-shadow(2px 1px 5px #fff)  brightness(250%) contrast(6.5)
        h3 
         color $whiteBlue

  .banner-item
    height: 500px
    background-repeat: no-repeat
    background-size: cover
    padding-top: 4rem
    padding-left: 16rem
    +desktop()
      background-position: 50% 0% !important

    +middle()
      padding-left: 0rem


    h2 
      color #fff
      font-size 50px
      font-weight: 700
      +middle()
        font-size 28px
        text-align: center

    a
      @extend .btn-whatsapp
      margin-top 2.5rem
      position relative
      margin-left 4rem
      +middle()
        margin-left 30%

      &:before
        content: ''
        width 61px
        height 61px
        background: url('../imgs/arrow-whats-clube.png') no-repeat 
        position: absolute
        left -75px
        top -20px
    .preco
      +middle()
        text-align: center
        margin 0 auto
      h3
        font-size 100px
        font-weight: 700
        color #fff
        display: flex
        line-height: 1
        +middle()
          justify-content: center
          font-size 60px


        span 
          font-size: 40px
          margin-top: 1rem
          +middle()
            font-size: 30px
          &:nth-child(2)
            font-weight: 700 
            font-size: 40px
            align-self: end;
            +middle()
              font-size: 30px
          &:nth-child(3)
            font-weight: 600 
            font-size: 24px
            align-self: end;
            margin-left 1rem
            padding-top: 1rem
            text-align-last: justify;
            +middle()
              font-size: 18px
      p 
        color #fff
        font-size: 24px 
        +middle()
          text-align: center
        &:last-child
          font-size: 12px

  .banner-clube-1
    background: url('../imgs/banner-plano-1.jpg')
    display: none
    &.show
      display: block
      animation: tab 0.5s forwards;


  .banner-clube-2
    background: url('../imgs/banner-plano-2.jpg')
    display: none
    &.show
      animation: tab 0.5s forwards;
      display: block

  .banner-clube-3
    background: url('../imgs/banner-plano-3.jpg')
    display: none
    &.show
      display: block
      animation: tab 0.5s forwards;

  .banner-clube-4
    background: url('../imgs/banner-plano-4.jpg')
    display: none
    &.show
      display: block
      animation: tab 0.5s forwards;
  