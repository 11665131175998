/*====  NAVIGATION ============================ */
#header 
  position fixed
  top 0
  left 0
  z-index 100
  width 100%
  box-shadow: 3px 4px 50px 1px rgba(0,0,0,0.19);

  .top-bar
    width 100%
    height 45px
    background $primaryBlue

    color #fff
    font-family 'Roboto', sans-serif;

    display flex
    align-items center

    .endereco
      font-size 12px

    .consultas
      font-size 12px
      justify-content center

    
    .contatos
      font-size 12px
      text-align right 
     
      .telefone
        justify-content end
        font-size 12px

      
      .whatsapp
        justify-content end
        font-size 12px

    img
      max-width 20px
      max-height 20px
      margin-right 7px


  nav 
    height 175px
    display flex
    align-items center
    justify-content space-between
    width 100%

    background #fff
    transition height 0.2s
    +mobile()
      height 100px

    .container
      .grid-menu
        display grid
        grid-template-columns 180px 1fr 200px 
        align-items: center
        justify-content space-between
        text-align right
        +middle()
          grid-template-columns 180px 1fr

          .grid 
            display: none
      .logo
        img
          max-width 180px
          position: relative
          z-index 99999

      .btn-whatsapp
        @extend .btn-whatsapp
        justify-self: end;

      ul 
        li 
          text-align center
          // color $subtitle-color
          +middle()
            text-align left
           

          a 
            transition color 0.2s
            position relative
            font-family 'Mukta Vaani', sans-serif
            font-size 16px
            color $subtitle-color

            &:hover
              color $activelink!important
      

    .menu 
      opacity 0
      visibility hidden
      top -20rem
      transition 0.2s
      margin-left 2rem
      position relative

    .menu 
      display none

      .menu-item-has-children
        display: flex
        align-items: center
        gap: 0.2rem
        +middle()
          flex-direction: column
          align-items:flex-start
          .sub-menu
            margin-left: 1rem
            list-style: circle;

        
        #myCollapse 
          .open-cat
            display block
          &[aria-expanded="false"]
            .close-cat
              display none

            .open-cat
              display inline-block

          &[aria-expanded="true"]
            .close-cat
              display inline-block

            .open-cat
              display none

        &::after
          content ''
          display block
          font-size: 20px
          width: 8px
          height: 5px
          background: url(../imgs/arrow-down.png)
          +middle()
            display: none

        &:hover
          &::after
            filter invert(52%) sepia(39%) saturate(803%) hue-rotate(156deg) brightness(90%) contrast(84%);
          ul 
            +desktop()
              display: block
              position: absolute;
              top: 25px;
              background: #ebf2fe;
              padding: 1rem;
              border-radius: 4px
              li
                text-align: left
        ul.show
          +desktop()
            display: block
            position: absolute;
            top: 25px;
            background: #ebf2fe;
            padding: 1rem;
            border-radius: 4px
            li
              text-align: left

    .icon-close 
      visibility hidden
      opacity 0

      position absolute
      top -1.5rem
      right 1.5rem

      transition 0.2s

    .icon-open 
      // justify-self: end

    /* toggle menu */
    .toggle 
      color $primaryBlue
      font-size 1.5rem
      font-weight 400
      cursor pointer
      /* Mostrar menu */

    &.show 
      .container
        .grid-menu
          .grid 
            display: block
          .logo 
            img
              top 12px

      .menu 
        opacity 1
        visibility visible

        background #fff

        height 100vh
        width 100vw
        margin 0

        position fixed
        top 0
        left 0

        display grid
        place-content flex-start
        padding-top: 8rem 
      
        justify-content: start
        padding-left: 2rem
        +mobile()
          gap 1rem

        ul 
        
          li
            text-align: left



      .icon-close 
        visibility visible
        opacity 1
        top 1.5rem

  @media (min-width: 1000px) 
    /* navigation */
    nav 
      .container
        .menu 
          opacity 1
          visibility visible
          top 0
          display flex
          align-items center
          justify-content: space-between
          // ul
          //   display: none

      .icon-open 
        display none
    

li.item-menu.sub-item
  color rgb(172, 172, 172)