.menu-rodape
  margin-bottom: 0.5rem
  &:nth-child(5)
    grid-row span 2

  .title-category
    @extend .p-sub
    color #fff!important
    font-weight 700
    margin-bottom: 0.5rem
    display: block
  .list-item 
    @extend .p-sub
    font-weight: 400
    color #fff!important
    display: flex
    align-items: center

    &::before
      content ''
      display: block
      min-width 4px
      min-height 4px
      border-radius: 50%
      background: #fff
      margin-right: 5px!important