#quem-somos-page

  @extend #quem-somos
  
  .img-quem-somos
    max-width 620px
    margin-left 0
    margin-right 0

    +middle()
      margin 0

    +desktop()
      background: url('../imgs/foto-rt-medicos.jpg') no-repeat
      background-size: cover;
    +mobile()
      max-width 290px
      height 235px
      margin 2rem auto

  .copy-quem-somos
    h2
      span
        +desktop()
          &::after
            content: "\a";
            white-space: pre;

    p
      margin-top 1rem 
      margin-bottom 2rem

#galeria-quem-somos
  margin-top 100px
  .copy
    text-align: center

    h3
      @extend .h3-sub

    h2 
      @extend .h2-sub
  
  // .splide__arrow--next
  //   right -2em!important
  
  // .splide__arrow--prev
  //   left -2em!important

  
  #galeria-desktop
    max-width 900px
    margin 0 auto
    .images
      display: grid
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)) 
      gap 1rem
      img 
        width 280px
        height 250px
        object-fit cover

