#page-club
  margin-top 100px
  .copy-club
    +middle()
      text-align: center

    h3
      @extend .h3-sub

    h2 
      @extend .h2-sub

    .content-club 

      p 
        @extend .p-sub
        font-weight: 700!important
        color #606060
        margin-bottom 6px
        +middle()
          text-align: left
      h4 
        @extend .p-sub
        font-weight: 700!important
        margin-top 18px
        margin-bottom: 12px
        color #606060
        font-size: 16px
      ul
        margin-top 1.3rem
        li
          @extend .p-sub
          font-weight: 700!important
          color #606060
          margin-bottom 6px
          display: flex
          align-items: center
          gap 0.5rem
          text-align: left

          &::before
            content ''
            display: block
            width 20px
            min-width 20px
            height 20px
            background: url('../imgs/icon-check.png') no-repeat

  .img-clube 
    max-width: 609px
    height: 600px
    background: url('../imgs/img-clube.jpg') no-repeat
    background-size cover
    box-shadow 0px 9px 24px 0px rgba(5,117,230,0.5)
    mix-blend-mode: multiply