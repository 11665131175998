main
  position relative
  margin-top 220px
  +mobile()
    margin-top 100px


#banner
  height: 530px
  width 100%
  background: url('../imgs/bg-banner.jpg')
  background-size 120%
  background-position 40% 0
  background-repeat no-repeat
  padding-top 5rem
  +mobile()
    background: url('../imgs/banner-mobile.jpg') no-repeat
    background-size cover
    margin-bottom: 30rem!important
    
  +middle()
    padding-top 3rem
    margin-bottom: 10rem


  +desktop()
    height: 575px
    
  .copy
    +mobile()
      text-align: center
      margin-top 70%
    a 
      +mobile()
        margin 0 auto

    h1
      font-family 'Open Sans', sans-serif
      font-size 40px
      color #fff
      font-weight 700
      letter-spacing -0.4px
      max-width 440px
      line-height: 1
      margin-bottom 16px

      +middle()
        font-size 27px
        max-width 100%

      span
        color $textStrong !important

    h2
      font-family 'Open Sans', sans-serif
      font-size 16px
      color #fff
      font-weight 600
      letter-spacing -0.4px
      max-width 308px
      margin-bottom 28px
      span
        color $textStrong !important

//Cards do banner

#cards-grid 
  margin-top 8%

  +mobile()
    margin-top 15%


  .card-item
    max-width 370px
    height 120px
    background url('../imgs/bg-card-banner.png')
    background-size cover
    border-radius 4px
    transition 0.2s
    +mobile()
      max-width: 300px
    &:hover
      filter brightness(0.9)

    .img-card 
      max-width 60px
      max-height 60px

    h3
      @extend .h3-sub 
      font-size 24px
      color #fff
      margin 0
      
#depoimentos
  margin-top 100px
  +middle()
    margin-top 50px

  .copy
    text-align center
    margin 0 auto
    margin-bottom: 4rem
    h3
      @extend .h3-sub

    h2 
      @extend .h2-sub
      margin: 1.5rem auto;
      max-width: 415px;
    a 
      display: flex
      gap 0.5rem
      margin-left auto
      justify-content: end;
      text-decoration:underline
      transition: opacity 0.2s
      width 100%
      max-width 195px
      font-family 'Open Sans', sans-serif
      font-size 14px

      &:hover
        opacity 0.8
      img 
        width: 20px
        height: 20px

  #depoimentos-slide
    margin: 0 auto
    .depoimentos-container
      padding 2rem 1rem

    .depoimento-box
      max-width: 360px
      height: 355px
      padding: 4rem 1rem 1rem 2rem
      box-shadow: 0px 14px 20px 0px rgba(39,71,110,0.13)
      position: relative
      margin: 0 auto;
      border-radius 4px

      .icon-google
        max-width: 80px
        height: 80px  
        position: absolute
        top -40px
        left 30px
        box-shadow: 0px 14px 20px 0px rgba(39,71,110,0.13)
        border-radius: 50%

      blockquote
        font-family 'Open Sans', sans-serif 
        font-weight 600
        color #6d6e71
        font-size 14px
        height 100%
        justify-content space-between
        footer
        //  justify-self: end

          .author
            font-size 16px
            font-weight 700
            color #48b8e1
          img 
            width: 95px
            height: 15px  

          p 
           color #6e6e6e
           @extend .p-12

  .splide__pagination
    bottom: -1.5em;
    text-align: center
    li
      button
        background:#48b8e1  
  .splide__pagination__page.is-active
    opacity 0.8

