#trabalhe-conosco
  margin-top 100px
  .copy
    +middle()
      text-align: center

    h3
      @extend .h3-sub

    h2 
      @extend .h2-sub
    
    p 
      @extend .p-sub
      margin-bottom 6px
      +middle()
        text-align: left

    form 
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1.5rem;
      margin-top: 1.5rem;
      max-width: 37.18rem;
      +mobile()
        display: flex
        flex-direction: column
        


      .send-cv
          grid-column 1 / 3
          font-family 'Open Sans', sans-serif 
          font-size 12px
          font-weight: 700
          color $subtitle-color
          justify-self flex-end


      label 
        margin-bottom: 1.5rem
        display: block
    
        input 
          width 100%
          height 2.5rem
          padding 14px
          border 1px solid #2a3b44
          border-radius 4px
          
          &::placeholder
            @extend .p-12
            font-weight 600
            color $subtitle-color

        &:nth-child(6)
          position relative
          grid-column 1 / 3
          padding-left 9.2rem
          margin-bottom: 6px

        input[type="file" i]
          font-family 'Open Sans', sans-serif !important
          font-size 12px
          padding-left: 1rem
          padding-top: 10px
      

        input[type="file"] 
          
          &::-webkit-file-upload-button
            background #7b7b7b
            font-family 'Open Sans', sans-serif !important
            font-size 12px
            color #fff

            top 0
            left 0
            position: absolute

            width 160px
            height 2.5rem
            border none
            border-radius: 4px
     
        
      button 
        grid-column 2 / 3
        grid-row 5 / 6
        justify-self flex-end

        max-width 160px
        width 100%
        height 50px
        margin 1rem 0

        background: linear-gradient(90deg, rgba(43,113,181,1) 0%, rgba(24,79,123,1) 100%);
        border none
        border-radius: 4px

        font-family 'Open Sans', sans-serif 
        font-size 16px
        font-weight: 700
        color #fff

        +mobile()
          grid-column 1 / 3
          justify-self flex-start
          max-width 100%


  .img-trabalhe
    max-width: 400px
    height: 600px
    background: url('../imgs/img-trabalhe.jpg') no-repeat
    background-size cover
    background-position: top center

    border-radius: 4px
    box-shadow 0px 9px 24px 0px rgba(5,117,230,0.5)
    mix-blend-mode: multiply

    +mobile()
      max-width 290px
      height 235px
      margin 0 auto
      margin-top 1.5rem
      background-position: top center