#posts-exames
  margin-top 100px


.posts-list
  display grid
  grid-template-columns 1fr 1fr
  grid-auto-flow: column;
  gap 1rem
  +middle()
    grid-template-columns 1fr


.load-more
  border none
  background none
  width 120px
  margin 0 auto
  padding 1rem
  display: flex
  align-items: center
  gap 0.3rem

  font-family 'Open Sans', sans-serif
  color $subtitle-color
  font-weight: 600
  font-size 16px
  img
    width 10px
    height 10px
    object-fit contain
    margin-top 5px

.search-bar
  position relative
  width: 100%
  margin-bottom: 3rem
  input
    border: 1px solid #00a2f1
    border-radius: 4px
    padding 1rem 
    position: relative
    width: 100%
    padding-left: 3rem;
   
    font-family 'Open Sans', sans-serif
    color $subtitle-color
    font-weight: 600
  .lupa
    width 25px
    height 25px
    display: block
    background: url('../imgs/icon-lupa.png') no-repeat
    position: absolute
    top 15px
    left 20px

.searchwp-live-search-results-showing
  width: 30%!important
  +mobile()
    width: 80%!important

.searchwp-live-search-results
  min-height: 80px!important

.searchwp-live-search-result 
  a
    font-family 'Open Sans', sans-serif 
    font-size 16px
    color #0081b8!important
    font-weight: 700

.content-exame
  .copy-exames
    font-family 'Open Sans', sans-serif
    color $subtitle-color
    font-weight: 600
    font-size 13px
    margin-bottom 1rem
    strong
      font-weight: 700


  h3 
    font-family 'Open Sans', sans-serif
    color #051221
    font-weight: 700
    font-size 24px
    // text-transform: capitalize