.post-container
  display flex
  flex-direction column
  align-items: center

  justify-content: center;

  padding 1rem
  height 85px
  min-width 300px
  // transition all 0.1s

  grid-row span 1
  border-left 2px solid #00a2f1
 
  box-shadow 0px 3px 17.46px 0.54px rgba(0, 68, 105, 0.18);
  
  border-radius: 4px
  +mobile()
    height auto


  &:nth-of-type(2n)
    grid-column 2/3
    +middle()
      grid-column 1/2



  &:nth-of-type(2n+1)
    grid-column 1/2
    


  &.show
    grid-row span 4
    padding 1rem
    height 350px
    flex-direction column
    align-items: start
    justify-content: start;
    
    +mobile()
      height 380px

    header
      h2
        margin-top 10px

      .btn-open
        cursor: pointer
        
        &:before 
          transform: rotate(90deg)

        &:after
          transform: rotate(180deg)

  header
    display flex
    justify-content space-between
    align-items center
    width 100%
    +mobile()
      align-items flex-start
    
    h2
      font-family 'Open Sans', sans-serif 
      font-size 18px
      color #48b9e1
      font-weight: 700
      max-width: 70%;
    
      &:not(:first-child)
        font-size 16px
        font-weight: 600
      +mobile()
        font-size 16px


    .btn-open
      position: relative
      min-width: 25px
      height: 25px
      // background: transparent
      border: 3px solid #48b9e1
      border-radius:50%
      // padding: 0.3rem
      
      &:before,
      &:after
          content: ""
          position: absolute
          // background-color: white
          transition: transform 0.25s ease-out

      /* Vertical line */
      &:before
          top: 1px;
          left: 10.5px;
          width: 2px
          height: 90%
          margin-left: -2px
          background: #48b9e1

      /* horizontal line */
      &:after
          top: 10.9px;
          left: 1px;
          width: 90%;
          height: 2px
          margin-top: -2px
          background: #48b9e1

  .entry-content
    +mobile()
      align-self: stretch;

    h4
      font-family 'Open Sans', sans-serif 
      font-size 16px
      color $subtitle-color
      font-weight: 700
      margin 0.5rem 0
      margin-top 1.3rem
    p 
      font-family 'Open Sans', sans-serif 
      font-size 14px
      color $subtitle-color
      font-weight: 400

    .info
      font-weight 700
      margin-top: 1rem
 
      
@media (max-width: 400px)
  .post-container
    height 110px
