  
* 
  margin 0 
  padding 0
  box-sizing border-box

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video 
	margin 0
	padding 0
	border 0
	font-size: 100%
	font inherit
	vertical-align: baseline


ul 
  list-style none
  margin 0

a 
  text-decoration none
  color inherit!important

img 
  width 100%
  height auto
  display block

html 
  scroll-behavior smooth

p
 margin 0 

h2,h3,h4,p
  font-family 'Open Sans', sans-serif 


@keyframes tab {
  from {
    opacity: 0;
    transform: translate3d(-60px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0px, 0, 0);
  }
}