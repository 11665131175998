#agendamento
  background url('../imgs/bg-agendamento.jpg') no-repeat
  background-size cover
  width 100%

  text-align center
  margin 0 auto
  padding: 3rem 0
  margin-top: 100px
  .copy 
    max-width: 700px
    margin 0 auto

  h3 
    @extend .h3-sub
    color #fff
    
  h2
    @extend .h2-sub
    color #00bbfe
    margin 2rem 0
  p 
    font-size 16px
    font-weight 400
    color #fff
    // margin 0 auto

  form
    display flex
    justify-content center
    margin 2rem 0
    gap 1rem
    +mobile()
      flex-direction column

    input 
      width 80%
      padding: 0 1rem
      font-family 'Open Sans', sans-serif 
      font-weight 600
      color $subtitle-color
      border-radius 4px
      border none
      outline none
      +mobile()
        width 100%
        height 50px

      &::placeholder
        font-family 'Open Sans', sans-serif 
        font-size 16px
        font-weight 600
        color #a9a9a9

      &:focus
        border 1px solid #00bbfe

  

    a
      +mobile()
        width 100%
      &::after
        +mobile()
          width 100%

  .contato
    &:first-child
      +desktop()
        justify-content: end !important

    .img-contato
      max-width 60px
      height 60px 
    a
      font-size 14px
      color #fff
      font-weight 400
      span 
        font-weight 700