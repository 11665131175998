#exame-page
   margin-top 4rem

  .img-exames
    max-width 560px
    height 560px
 
    +middle()
      margin 0 auto
      margin-top 2rem
    
    +mobile()
      max-width 290px
      height 235px
      margin 2rem auto
      margin-bottom: 1rem
 
    img 
      box-shadow 0px 9px 24px 0px rgba(5,117,230,0.5)
      +desktop()
        position: sticky
        top: 175px;

  .copy-single

    .content-single
      h4,h3
        @extend .h2-sub
        font-size: 19px
        text-transform: none!important
        margin-top 1rem
    +middle()
      text-align: center

    h3
      @extend .h3-sub
      text-transform: uppercase

    h2 
      @extend .h2-sub
    h4
      @extend .h2-sub
      font-size: 22px
    p 
      @extend .p-sub
      // margin-top 1rem
      // margin-bottom 2rem
      max-width 470px
      font-weight: 400
      // margin 0 auto
      +middle()
        max-width 100%
    ul 
      @extend .p-sub
      list-style disc
      list-style-position:  inside
      font-weight: 400
  .btns
    .valor
      width 180px
      min-width: 160px
      height 50px
      border 1px solid $subtitle-color
      border-radius: 4px
      text-align: center

   
      p 
        @extend .p-sub
        color $subtitle-color
        font-weight: 700
        margin 0
      h2 
        @extend .p-sub
        font-size: 18px
        color #0081b8