#blog
    margin-top: 0px;
    margin-bottom: -100px;
    padding-top: 4rem


    .border-boxs 
        border-radius: 15px;
    .container-posts
        .container 
            padding-left 0px 
        .row 
            padding-left 0px 
    
    .slide-topo-blog 
        height: 450px;
        width 100%
        margin-bottom 36px
        img  
            height: 450px;
            width 100%
            object-fit cover 
    .slick-list 
        border-radius: 15px;
    .img-side
        margin: 0 auto;
        max-width: 180px

    .box-post 
        border-radius: 15px;
        img 
            width 100%
            height 198px
            object-fit cover 
            border-top-left-radius 15px
            border-top-right-radius 15px
            
        margin-bottom 37px
        .text 
            padding-top 27px
            padding-left 26px
            padding-right 26px
            padding-bottom 33px 

            h2 
                font-family 'Open Sans', sans-serif 
                font-size 19px 
                font-weight bold
        .post-dates 
            margin 10px 0
            
            p 
                margin-right 4px
                color $activelink 
                i 
                    color $activelink 
    .box-sidebar
        margin-bottom 49px
        .text
            padding-bottom 20px
            padding-left 21px 
            padding-right 21px
        .bg 
            img 
                width 100%

                margin-bottom 18px
    
        .title-sidebar 
            font-family 'Open Sans', sans-serif 
            font-size 33px 
            font-weight 900 
            color $subtitle-color 
            line-height 1.19
        a 
            color $subtitle-color
            font-size 14px 
            font-weight bold 
            font-family 'Open Sans', sans-serif 


        .box-post-visitados 
            margin-top: 15px;
            img 
                width: 62px;
                height: 62px;
                object-fit: cover;  
       
                
            h4 
                font-family 'Open Sans', sans-serif 
                font-size 15px
                font-weight  900 
                margin-left: 12px;
                height: 62px;
                display: flex;
                align-items: center;
        .categories-sidebar 
            margin-left: 25px;
            margin-top 15px

        .txt-redes
    
            strong
                font-family 'Open Sans', sans-serif 
                font-size 14px

                span
                    font-family 'Open Sans', sans-serif
                    font-size 14px
                    color $activelink
                    font-weight bold 
        .redes-footer 
            font-family 'Open Sans', sans-serif
            font-size: 12px
            a 
                font-family 'Open Sans', sans-serif
                i 
                    
                    font-size 20px
                    margin-right 5px
                    margin-left 10px 
                    color $subtitle-color

                    
    .side-news 
        text-align left !important
        padding-top 26px
        strong 
            font-family 'Open Sans', sans-serif
            font-size 22px
            font-weight 600
            line-height 24px
            span 
                font-family 'Open Sans', sans-serif
                font-weight bold
                color $activelink
                font-size 22px
        .btn-outline-secondary
            &:hover
                background $activelink
                i 
                    color #fff 
        form 
            ::placeholder
                font-weight 500

    .slick-dots 
         
        display flex
        align-items center
        justify-content center
        margin-top -70px
        position relative
        li 
            list-style-type none
            button 
                font-size 0
                background transparent
                padding 5px
                border none
                border-radius 10px
                border 1px solid #fff
                margin-right 6px
        .slick-active 
            button 
                background  #fff !important 
    .slide-topo-blog 
        .bg 
            &:before 
                content ""
                width 100%
                height 322px
                top: 129px;
                position absolute
                left 0
                background-image: linear-gradient(to bottom,rgba(255,255,255,0),#005c8930 52%,rgba(0,92,137,0.271));
        .txt-post-slide 
            position absolute
            margin-top -272px
            z-index 9
            margin-left 56px
            h1 
                color #fff
                font-weight bold
                font-size 35px
                max-width 517px
                margin-top: 31px;
                margin-bottom: 27px;
                font-family 'Open Sans', sans-serif

             
            p 
                font-family 'Open Sans', sans-serif
                color #fff
                max-width: 538px;
    .nav-slider 
        display flex
        position absolute
        margin-top -256px
        width 100% 
        .prev 
            right 42px
            position absolute
            font-size 25px
            cursor pointer
            i
                color #ffffff9e
        .next 
            position absolute
            left 16px
            font-size 25px
            cursor pointer
            i
                color #ffffff9e
        
    #single-post 
        h1  
            font-size 40px
            font-weight 600
            color $subtitle-color
            font-family 'Open Sans', sans-serif

        .container-content 
            .date 
                margin 10px 0
                color $subtitle-color
                font-family 'Open Sans', sans-serif
                i 
                    color $subtitle-color
            ul 
                margin-left: 23px;
            
            strong 
                font-family 'Open Sans', sans-serif
                color $subtitle-color
            h2 
                font-family 'Open Sans', sans-serif
                color $subtitle-color
                font-size 20px
                font-weight 600

            h3 
                font-family 'Open Sans', sans-serif
                color $subtitle-color
                font-size 20px
                font-weight 600
            h4 
                font-family 'Open Sans', sans-serif
                color $subtitle-color
                font-size 20px
                font-weight 600
            h5 
                font-family 'Open Sans', sans-serif
                color $subtitle-color
                font-size 20px
                font-weight 600
            h6 
                font-family 'Open Sans', sans-serif
                color $subtitle-color
                font-size 20px
                font-weight 600
        .col-banner 
            img 
                height 334px
                width 100% 
                object-fit cover
        .container-content 
            img 
                width 100%
        .title-artigos-rl 
            font-family 'Open Sans', sans-serif
            font-size 30px
            font-weight 600 
            color $subtitle-color
            margin-bottom 23px
        .relacionados 
            img 
                width 100%
                height 177px
            h6 
                font-family 'Open Sans', sans-serif
                font-size 15px
                margin-top 15px
                margin-bottom 15px  
    .contato-none 
        display none 
    .box-sidebar-img 
        padding-top 20px
    footer 
        margin-top 60px 
  
    /* Small only */
    @media screen and (min-width: 320px) and (max-width: 63.9375em) 
        .container-posts
            .container 
                padding 0px 

        .box-post 
            text-align left
            .post-dates
                display  block !important

            .text 
                h2 
                    font-size 18px
        .mobile-p 
            padding 0px 
        .nav-slider 
            .prev 
                right: 16px
        .slide-topo-blog 
            .txt-post-slide 
                margin-top 0px
                z-index 9
                position relative
                margin-left 10px
                margin-right 10px
                height 232px
                text-align center
                font-family 'Open Sans', sans-serif
                h1  
                    font-family 'Open Sans', sans-serif
                    margin-top -233px
                    max-width 320px
                    font-size 16px
            .title-artigos-rl 
                font-family 'Open Sans', sans-serif
                font-size 21px
        #single-post 
            text-align center
            h1 
                font-family 'Open Sans', sans-serif
                font-size 21px
            .container-content 
            
                h2 
                    font-size 17px
                    font-family 'Open Sans', sans-serif
                h3 
                    font-size 17px
                    font-family 'Open Sans', sans-serif
                h4 
                    font-size 17px
                    font-family 'Open Sans', sans-serif
                h5 
                    font-size 17px
                    font-family 'Open Sans', sans-serif
                h6 
                    font-size 17px
                    font-family 'Open Sans', sans-serif
            .title-artigos-rl 
                font-size 21px
                font-family 'Open Sans', sans-serif
            .slick-dots 
                margin-top -6px
            



    /* Large and up */
    @media screen and (min-width: 64em)
        .sidebar 
            padding 0px
        #single-post 
            .container-content 
                padding-right 85px
			.box-post
                height 460px
        .page-padrao 
            padding-top 40px
            padding-bottom 60px
            .container-content 
                padding-left 85px 
            
.slick-dots li button:before
	content '' !important
            
.paginacao 
	margin-top: 50px
	margin-bottom: 80px
	.page-numbers.current 
		color: #fff
		background-color: $primaryBlue
	.page-numbers 
		border: 1px solid #cacaca;
		padding: 15px
		font-weight: bold
		color: #515151

