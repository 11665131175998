#covid
  background: url('../imgs/bg-covid.jpg')
  background-size cover
  background-repeat no-repeat
  width 100%
  height: 392px
  margin-top 160px
  margin-bottom: 300px
  
  +middle()
    margin-top: 80px

  .img-covid
    background url('../imgs/imagem-medico-covid.png')
    background-size cover
    background-repeat no-repeat
    max-width: 367px
    height 534px
    margin-top: -142px;

  .copy
    text-align center
    margin-top -60px
    +middle()
      margin-top 50px

    h2 
      font-size 40px
      font-weight 700
      color #fff
      margin-bottom: 1rem
      +middle()
        font-size 26px
        color #fff

    h3
      font-size 24px
      font-weight 600
      color #fff
      +middle()
        font-size 18px
    a
      margin 22px auto 2rem

    p 
      color #fff
      @extend .p-12
      max-width: 440px
      margin: 0 auto
  #cards-covid
    margin: 0 auto
    margin-top -10%

    +middle()
      margin-top 25px
    +mobile()
      margin-top -5px

    .splide__list
      padding: 2rem 0!important
    // .splide__track
      // display: flex
      // justify-content: center

    .splide__slide
      &:hover 
        .item-covid
          display: none
        .item-covid-alert
          display: block


    .item-covid, .item-covid-alert
      width 173px
      height 235px
      background $whiteBlue
      border-radius 4px
      text-align center
      padding 28px 16px 10px
      position relative
      box-shadow 0px 9px 24px 0px rgba(93,160,222,0.33)  
      transition 0.2s
      margin 0 auto
      display: flex
      flex-direction: column
      justify-content: space-between

      &:hover 
        filter brightness(0.95)
        .item-covid-alert
          display block
       

      //Linha em cima do preço

      &::after
        content ''
        display block
        width 100%
        height 1px
        background #e3ecf5
        position absolute
        bottom 23%
        left 0

      img
        max-width 63px
        height 63px
        margin 0 auto

      h3 
        color $primaryBlue
        font-size 15px
        font-weight 700

      h4 
        color $primaryBlue
        font-size 15px
        font-weight 700
        span 
          font-size 30px

    .item-covid-alert
      display none
      width 173px
      background $primaryBlue
      p
        color #fff
        font-size 13px
        font-weight 600
      &::after
        display: none

  .splide__pagination
    bottom: -1.5em;
    text-align: center
    display: none
    +middle()
      display: block
    li
      button
        background:#48b8e1  
  .splide__pagination__page.is-active
    opacity 0.8
