#page-agendamento
  margin-top 100px

  h2 
    @extend .h2-sub
  
  p 
    @extend .p-sub
    margin-bottom 6px
    +middle()
      text-align: left


  form
    display flex
    justify-content center
    margin 2rem 0
    gap 1rem
    +mobile()
      flex-direction column
    
    select 
      width 50%
      padding: 0 1rem
      font-family 'Open Sans', sans-serif 
      font-weight 600
      color $subtitle-color
      border-radius 4px
      border 1px solid #00bbfe
      outline none
      +mobile()
        width 100%
        height 50px

      &::placeholder
        font-family 'Open Sans', sans-serif 
        font-size 16px
        font-weight 600
        color #a9a9a9
        padding-bottom: 1rem

        

    button
      +mobile()
        width 100%
      &::after
        +mobile()
          width 100%
      &:disabled
        cursor: not-allowed


//BARRA DE PROGRESSO

.container-steps {
    margin: auto;
    text-align: center;
    max-width: 100%;
    width: 500px;
    
    
}

.progress-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    &::before {
        content: '';
        width: 100%;
        height: 2px;
        background: rgb(121, 121, 128);
        position: absolute;
        left: 0;
        top: 50%;
        z-index: -1;
    }
}

.steps 
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #b4b4b4;
  position: relative
  p 
    @extend .p-sub
    font-weight: 700
    position: absolute
    top: 60px
    left: 0px

    
  &:not(:last-child) {
    margin-right: 4rem;

  }
  &.ativo 
    background: #0081b8
    transition: all 0.6s ease-in-out;
    p 
      color #0081b8


.progress {
    width: 0;
    height: 4px;
    background: #0081b8
    position: absolute;
    left: 0;
    top: 50%;
    z-index: -1;
    transition: all 0.4s ease;

}

#box-agendamento
  margin-top 120px
  display block
  opacity 1
  transition: all 0.5s
  &.ativo-cadastro
    display none
    opacity 0
    transition: all 0.5s

  .box-medico
    .medico
      @extend .p-sub
      font-weight: 700
      color #051221
      font-size: 18px
      max-width: 920px
      margin: 0 auto
      margin-bottom: 4rem

    .box-agenda
      width: 100%
      max-width: 920px
      min-height 150px
      box-shadow 0px 9px 24px 0px rgba(5,117,230,0.5)
      border-left 2px solid #00a2f1
      border-radius: 4px
      padding 1rem 2rem
    
      margin 0 auto
      margin-bottom: 5rem

      position: relative

      .grid-horarios
        display: grid
        grid-template-columns: repeat(auto-fill, minmax(120px, 129px))
        align-items: center
        gap 1rem
        +middle()
          justify-content: center;
        +mobile()
          grid-template-columns: repeat(auto-fill, minmax(90px, 105px))

        button
          border 1px solid #9e9e9e
          border-radius: 4px
          max-width: 135px
          min-width: 100px
          width 100%
          height 50px
          background #fff
          @extend .p-sub
          font-weight: 700
          &:hover
            filter: brightness(1.1)
            border: 1px solid #0081b8

    .data
      position: absolute
      top -40px
      @extend .p-sub
      font-weight: 700
      color #051221
    h2 
      @extend .p-sub
      color #0081b8
      font-weight: 700
      margin-bottom 1rem
      display flex
      align-items: center
      gap: 0.5rem
      &::before 
        content ''
        display block
        width 30px
        height 30px
        background url('../imgs/icon-horario-disponivel.png') no-repeat
        background-size cover

#cadastro-paciente 
  margin-bottom: 150px
  margin-top: 90px
  opacity: 0
  display: none
  
  transition: all 2s
  &.ativo-cadastro
    display: block
    opacity: 1
    transition: all 2s
  h2 
    @extend .h2-sub
    text-align: center
    margin-bottom: 2rem
  form 
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    margin-top: 1rem;
    max-width: 37.18rem;
    align-items: center;
    margin: 0 auto;
    +mobile()
      display: flex
      flex-direction: column
        
    label 
      margin-bottom: 1rem
      display: block
      span.invalid 
        @extend .p-sub
        font-size: 10px
        color red
      input 
        width 100%
        height 2.5rem
        padding 14px
        border 1px solid rgba(0,92,137, 0.18)
        border-radius 4px
    
        &::placeholder
          @extend .p-12
          font-weight 600
          color #a9a9a9

    .enviar-consulta
      grid-column: span 2
      max-width: 220px
      margin: 0 auto
      width 100%
      margin-top: 2rem
    .radio 
      display flex
      @extend .p-sub
      font-weight 600
      font-size: 1rem
      gap 0.8rem
     
      input[type="radio"]
        width 20px
        height 20px


.confirmar
  margin-top: 80px

.confirma-container
  h2 
    @extend .h2-sub
    font-size: 2rem

.confirmacao

  h4 
    @extend .h3-sub
  p 
    @extend .p-sub

#page-agendamento
  iframe 
    margin: 0;
    width: 100%!important;
    // height: 100%!important;
    height: 600px