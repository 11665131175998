#contato
  margin-top: 100px
  .form-contato
    h3
      @extend .h3-sub

    h2 
      @extend .h2-sub
    
    p 
      @extend .p-sub

  form 
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    margin-top: 1rem;
    max-width: 37.18rem;

    label 
      margin-bottom: 1rem
      display: block

      &.msg 
        grid-column:span 2
        textarea
          background #e6effe
  
      input 
        width 100%
        height 2.5rem
        padding 14px
        border 1px solid rgba(0,92,137, 0.18)
        border-radius 4px
        background #e6effe
        &::placeholder
          @extend .p-12
          font-weight 600
          color #a9a9a9

      select
        width 100%
        height 2.5rem
        padding 0 14px
        border 0
        border-radius 4px
        background #e6effe
        border 1px solid rgba(0,92,137, 0.18)
        @extend .p-12
        font-weight 600
        color #a9a9a9   

        &::placeholder
          @extend .p-12
          font-weight 600
          color #a9a9a9      
        option
          @extend .p-12
          font-weight 600
          color #a9a9a9   
           

      textarea 
        padding 14px
        border 0
        border-radius 4px
        width 100%
        height 6rem
        border 1px solid rgba(0,92,137, 0.18)
        &::placeholder
          font-family 'Open Sans', sans-serif 
          font-size 12px
          font-weight 600
          color #a9a9a9
      
    button 
      grid-column 2 / 3
      grid-row 5 / 6
      justify-self flex-end


    
