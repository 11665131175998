.modal-body 
  padding: 2rem


.modal-list
  display grid
  grid-template-columns 1fr 2fr 1fr
  .menu-search
    border-left: 1px solid #dee2e6
    padding-left: 2rem
    p
      @extend .p-sub
      text-align: center
      margin-top 1rem

      span  
        color #00bcff


    .search-bar
      margin-top 1rem
      input
        border-radius 16px!important
        height 2rem
        border: 1px solid #8a8a8a

      .lupa
        width: 20px;
        height: 20px;
        top: 8px;
        left: 12px;
        background-size: cover;

  .list-menu
    border-right: 1px solid #dee2e6
    a 
      display: flex
      align-items: center
      gap: 1rem
      transition color 0.2s
      position relative
      font-family 'Mukta Vaani', sans-serif
      font-size 16px
      color $subtitle-color
      text-align: left
      margin-bottom: 2rem

      &:hover
        color #00bcff!important
        .icone-menu
          filter: invert(70%) sepia(84%) saturate(4106%) hue-rotate(160deg) brightness(101%) contrast(105%);

      .icone-menu
        max-width: 28px
        height 28px
        object-fit: none

  .list-itens
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(10, 1fr);
    grid-auto-flow: column;
    padding-left 1rem
    @extend .p-sub