#blog-sec
  margin-top 100px
  background url('../imgs/bg-blog.jpg') no-repeat
  background-size cover
  padding 4rem 0
  .copy
    text-align center
    margin 0 auto
    margin-bottom: 4rem
    h3
      @extend .h3-sub

    h2 
      @extend .h2-sub
      margin: 1.5rem auto;
      max-width: 315px;
    
    p 
      margin 0 auto
      @extend .p-sub

  .blog-container
    gap 1rem
    .blog-card
      max-width: 360px
      width 100%
      height: 490px
      background:#fff
      box-shadow: 0px 14px 20px 0px rgba(39,71,110,0.13)
      border-radius: 4px
      margin 0 auto
      .img-blog
        height: 250px
        object-fit: cover;
        object-position: top;
      header
        img
          border-radius: 4px 4px 0 0
    .content
      padding: 1rem
      max-height: 230px
      height: 100%
      h2 
        font-family 'Open Sans', sans-serif 
        font-size 18px
        font-weight: 700
        color #051221
      .data 
        font-family 'Open Sans', sans-serif 
        font-size 14px
        font-weight: 400
        color $textBlue

        display: flex
        align-items: center
        gap 0.5rem

        margin: -2rem 0
        img
          width: 15px
          height 15px

      article
        font-family 'Open Sans', sans-serif 
        font-size 14px
        font-weight: 600
        color $subtitle-color
      a 
        font-family 'Open Sans', sans-serif 
        font-size 14px
        font-weight: 600 !important
        color $textBlue !important
        text-decoration: underline

